import React from 'react'
import 'scss/gatstrap.scss'

import styles from "./card.module.css"

export const Card = (props) => {
    return (
        <>
            <a href={`${props.slug}`}>
                <div className={styles.card} style={{background: props["color"]}}>
                    <h3 style={{margin: "0px"}}>{props.name}</h3>
                    <img src={props.img} alt="popular_topic" className={styles.img}/>
                </div>
            </a>
        </>
    )
}
