import React from 'react'
import 'scss/gatstrap.scss'

import styles from "./chip.module.css"

const colorMap = {
    "diagnosis": "#FADF94",
    "symptom": "#E9D8FF",
    "treatment": "#A6E5E1",
    "diet": "#ffdfe7",
}

export const Chip = (props) => {
    const color = colorMap[props.type]
    return (
        <>
            <div className={styles.chip} style={{background: color}}>
                <a href={`${props.slug}`}>{props.name}</a>
            </div>
        </>
    )
}
