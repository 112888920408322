import React from 'react'
import PropTypes from 'prop-types'

import { Layout, DownloadLinks, JoinCommunity, SearchBar } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Card } from '../components/common/Card/card'
import { Chip } from '../components/common/Chip/chip'

const diagnosisIds = [14, 57, 37, 27, 4, 12, 1, 22, 33]
const symptomIds = [10, 68, 64, 4, 41, 116, 126, 17, 33, 22]
const treatmentIds = [149, 27, 78, 2, 14, 122, 63, 76, 96, 68]
const dietIds = [1, 4, 39, 46, 36, 51, 49]

const cards = [
    {
        "name": "COVID-19",
        "slug": "/diagnoses/coronavirus/",
        "color": "#FFDFE7",
        "type": "diagnoses",
        "img": "/images/icons/pop_topic_covid.svg"
    },
    {
        "name": "Anxiety",
        "slug": "/symptoms/anxiety/",
        "color": "#E9D8FF",
        "type": "symptoms",
        "img": "/images/icons/pop_topic_anxiety.svg"
    },
    {
        "name": "Depression",
        "slug": "/diagnoses/depression/",
        "color": "#A6E5E1",
        "type": "symptoms",
        "img": "/images/icons/pop_topic_depression.svg"
    },
    {
        "name": "Lyme Disease",
        "slug": "/diagnoses/lyme-disease/",
        "color": "#FADF94",
        "type": "diagnoses",
        "img": "/images/icons/pop_topic_lyme.svg"
    }
]

const Library = ({ location, pageContext }) => {
    const librarySearch = pageContext.librarySearch
    const diagnoses = librarySearch.filter(item => item["type"] === "diagnosis" && diagnosisIds.includes(item["id"]))
    const symptoms = librarySearch.filter(item => item["type"] === "symptom" && symptomIds.includes(item["id"]))
    const treatments = librarySearch.filter(item => item["type"] === "treatment" && treatmentIds.includes(item["id"]))
    const diets = librarySearch.filter(item => item["type"] === "diet" && dietIds.includes(item["id"]))
    return (
        <>
            <MetaData location={location} title={pageContext.title} description={pageContext.description}/>
            <Layout pageContext={pageContext} showSearch={false}>
                <div className="container-fluid" style={{padding: "50px", background: "#006AC7", color: "white"}}>
                    <div className="container" style={{textAlign: "center"}}>
                        <h1>Welcome to the WanaLibrary</h1>
                        <p>Whether you have Lyme or anxiety, learn from hundreds of relevant articles in the WanaLibrary. Additionally, you can browse through current books, podcasts and videos on a variety of health topics. You can also search community posts to see what is being said, IRL.</p>
                        <div styles={{height: "40px"}}/>
                        <SearchBar librarySearch={librarySearch}/>
                    </div>
                </div>
                <div className="container" style={{padding: "20px", marginTop: "20px"}}>
                    <h2 style={{textAlign: "center"}}>Browse Popular Topics</h2>
                    <div className="row" style={{marginTop: "20px"}}>
                        {cards.map((card, i) => (
                            <div key={i} className="col-12 col-md-6">
                                <Card name={card["name"]} slug={card["slug"].toLowerCase()} color={card["color"]} img={card["img"]}/>
                            </div>
                        ))}
                    </div>
                    <div className="row" style={{marginTop: "20px"}}>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex justify-content-start align-items-center" style={{margin: "20px 0px"}}>
                                <img src="/images/icons/diagnosis.svg" alt="Diagnosis" style={{height: "25px", width: "25px"}}/>
                                <h3 style={{marginLeft: "10px", marginBottom: "0px"}}><a href={"/diagnoses/"} style={{color: "#495057"}}>{"Diagnoses"}</a></h3>
                            </div>
                            <div className="d-flex" style={{flexWrap: "wrap"}}>
                                {diagnoses.map((item) => (
                                    <Chip name={item["name"]} slug={item["slug"].toLowerCase()} type={item["type"]}/>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex justify-content-start align-items-center" style={{margin: "20px 0px"}}>
                                <img src="/images/icons/symptom.svg" alt="Symptoms" style={{height: "25px", width: "25px"}}/>
                                <h3 style={{marginLeft: "10px", marginBottom: "0px"}}><a href={"/symptoms/"} style={{color: "#495057"}}>{"Symptoms"}</a></h3>
                            </div>
                            <div className="d-flex" style={{flexWrap: "wrap"}}>
                                {symptoms.map((item) => (
                                    <Chip name={item["name"]} slug={item["slug"].toLowerCase()} type={item["type"]}/>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex justify-content-start align-items-center" style={{margin: "20px 0px"}}>
                                <img src="/images/icons/treatment.svg" alt="Treatment" style={{height: "25px", width: "25px"}}/>
                                <h3 style={{marginLeft: "10px", marginBottom: "0px"}}><a href={"/treatments/"} style={{color: "#495057"}}>{"Treatments"}</a></h3>
                            </div>
                            <div className="d-flex" style={{flexWrap: "wrap"}}>
                                {treatments.map((item) => (
                                    <Chip name={item["name"]} slug={item["slug"].toLowerCase()} type={item["type"]}/>
                                ))}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <div className="d-flex justify-content-start align-items-center" style={{margin: "20px 0px"}}>
                                <img src="/images/icons/diet.svg" alt="Diet" style={{height: "25px", width: "25px"}}/>
                                <h3 style={{marginLeft: "10px", marginBottom: "0px"}}><a href={"/diets/"} style={{color: "#495057"}}>{"Diets"}</a></h3>
                            </div>
                            <div className="d-flex" style={{flexWrap: "wrap"}}>
                                {diets.map((item) => (
                                    <Chip name={item["name"]} slug={item["slug"].toLowerCase()} type={item["type"]}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <DownloadLinks/>
                </div>
                <JoinCommunity/>
            </Layout>
        </>
    )
}

Library.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default Library
